import React from "react"
import { graphql } from "gatsby"
import * as Icon from "react-feather"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Modernization is a Journey"
const pageTitle = "Modernization is a Journey"
const subTitle = "Modernization"
const para =`Application modernization is as much about evolving team culture as it is about implementing new technologies.
New processes need to be to be implemented to ensure Enterprise compliance standards (like SOC 2) are met.`
const blockquote = `"Successful transformation necessitates both reskilling teams and redefining roles as to emphasize collaboration,problem-solving, and shared ownership of production systems."`

const cardItems = [
  {
    id: 1,
    heading: `Foundation & Prepare`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          <strong>Challenge</strong> : Teams adhere to outdated patterns that
          aren't cloud-native, off-the-shelf solutions demand significant
          tailoring , and innovation is confined to each silo, so that “big
          bang” enhancements are diluted and ineffectual.
        </p>
        <p>
          <strong>Approach</strong> : Establish and implement a comprehensive
          enterprise Foundation (governance, networking, identity, security,
          observability, and pipelines) upon which transformations and
          modernizations may be built.
        </p>
        <p>
          <strong>Process : </strong>
        </p>
        <li>
          <Icon.Square />
          Incremental Journey
        </li>
        <li>
          <Icon.Square />
          Strategic Planning
        </li>
        <li>
          <Icon.Square />
          Continuous Adaptation
        </li>
        <li>
          <Icon.Square />
          Cultural Shift
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Build & Automate`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          <strong>Challenge</strong> : Establish and implement a comprehensive
          enterprise Foundation (governance, networking, identity, security,
          observability, and pipelines) upon which transformations and
          modernizations may be built.
        </p>
        <p>
          <strong>Approach</strong> : Teams struggle to modernize legacy systems
          due to data inconstancies, ambiguous requirements, integration
          challenges , security concerns, cultural resistance, and skill gaps.
        </p>
        <p>
          <strong>Process : </strong>
        </p>

        <li>
          <Icon.Square />
          Collaboration
        </li>
        <li>
          <Icon.Square />
          Streamlining Delivery
        </li>
        <li>
          <Icon.Square />
          Increased Agility and Flexibility
        </li>
        <li>
          <Icon.Square />
          Continuous Improvement
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Optimize & Evolve`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          <strong>Challenge</strong> :A lack of product-centricity and business
          alignment in technology impedes agility, leads to resource
          misallocation, hinders collaboration, and ultimately slowing
          responsiveness to customer demands.
        </p>
        <p>
          <strong>Approach</strong> : Expand operational flexibility, integrate
          specialized tools for enhanced capabilities, and refine observability
          and cost management practices as a control plan. Focus on end-user
          empowerment and optimal resource utilization.
        </p>
        <p>
          <strong>Process : </strong>
        </p>
        <li>
          <Icon.Square />
          Migration Factory
        </li>
        <li>
          <Icon.Square />
          Developer Experience (DevX)
        </li>
        <li>
          <Icon.Square />
          KPIs and SLAs
        </li>
        <li>
          <Icon.Square />
          Reduce Cost{" "}
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query ModernizationIsajourneyQuery {
    casedetailsJson(slug: { eq: "modernization-is-a-journey" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
